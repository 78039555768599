.rainbow {
	width: 20em;
	height: 10em;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.rainbow span {
	position: absolute;
	box-sizing: border-box;
	width: calc(100% - 2em * (var(--n) - 1));
	height: calc(200% - 2em * (var(--n) - 1));
	border: 1em solid;
	border-top-color: transparent;
	border-left-color: transparent;
	border-radius: 50%;
	transform: translateY(5em) rotate(225deg);
	animation: rotating 3s infinite;
	animation-delay: calc(0.05s * var(--n));
}

.rainbow span:nth-child(1) {
	--n: 1;
	color: orangered;
}

.rainbow span:nth-child(2) {
	--n: 2;
	color: orange;
}

.rainbow span:nth-child(3) {
  --n: 3;
  color: yellow;
}

.rainbow span:nth-child(4) {
  --n: 4;
  color: mediumspringgreen;
}

.rainbow span:nth-child(5) {
  --n: 5;
  color: deepskyblue;
}

.rainbow span:nth-child(6) {
  --n: 6;
  color: mediumpurple;
}

@keyframes rotating {
	0%, 20% {
		transform: translateY(5em) rotate(225deg);
	}

	80%, 100% {
		transform: translateY(5em) rotate(calc(225deg + 360deg));
	}
}
