:root {
  --main-pink: #f7847e;
  --main-green: #68c73d;
  --main-orange: #eeaa40;
  --main-gray: #545b62;

  --light-orange: #ffeaca;
}

html {
  scroll-behavior: smooth;
}

html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

#menu {
  width: 100vw;
  height: 60px;
  position: fixed;
  background: #C6FFDD;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
  z-index: 2;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
}

.menu-subtitle {
  color: white;
  font-size: 22pt;
  font-weight: 600;
  display: block;
  width: 200px;
  transition: all 0.3s
}

.menu-subtitle-mobile {
  color: white;
  font-size: 22pt;
  font-weight: 600;
  display: none;
  padding: 0 10px;
  transition: all 0.3s
}

.menu-subtitle:hover, .menu-subtitle-mobile:hover {
  opacity: 0.6;
}

#menu-subtitle-happyarc {
  width: 300px;
  padding-top: 7px;
}

.menu-subtitle img {
  width: 220px;
  fill: white;
}

.menu-subtitle-mobile img {
  width: 60px;
  fill: white;
}

a.menu-subtitle {
  color: white !important;
  text-decoration: none !important;
}

a.menu-subtitle-mobile {
  color: white !important;
  text-decoration: none !important;
}

#koFiIcon {
  height: 100%;
  padding: 10px;
}

#koFiMobileIcon {
  color: var(--main-pink);
}

#fakeMenu {
  width: 100vw;
  height: 60px;
  bottom: 0;
  position: absolute;
  background: #C6FFDD;
  background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD);
}

#hero {
  width: 100%;
  background: #C6FFDD;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
  position: relative;
  opacity: 1;
  display: flex;
  justify-content: flex-end;
  min-height: 75vh;
  max-height: calc(100vh - 150px);
  flex-direction: column;
  z-index: 3
}

#mainTitle {
  font-size: 80pt;
  padding: 5%;
  font-weight: 600;
  color: white;
}

#rainbowGraphic {
  position: relative;
  height: 277px;
  display: flex;
  justify-content: center;
}

.graphic {
  display: flex;
  flex-direction: column-reverse;
  height: 215px;
}

#totalTextHero {
  position: absolute;
  bottom: 80px;
  font-size: 15pt;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px 3px #33333370;
}

#recoveredTotalCountHero {
  background: white;
  position: absolute;
  bottom: -1px;
  font-size: 20pt;
  text-align: center;
  min-width: 450px;
  padding: 7px 15px;
  border-radius: 40px 40px 0 0;
  font-family: unset;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--main-green);
  height: 64px;
}

.recoveredTotalCountHeroSubtitle{
  font-size: 12pt;
  color: var(--main-gray);
  opacity: 0.4;
  line-height: 10px;
}

#recoveredTotalCountMenu {
  position: absolute;
  bottom: 0;
  font-size: 28pt;
  text-align: center;
  width: 100vw;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
}

#cookieConsent {
  position: absolute;
  display: flex;
  bottom: -80px;
  right: -40px;
  z-index: 5;
  max-width: 500px;
  font-size: 13px;
  color: var(--main-gray);
  background: #C6FFDD;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FBD786, #C6FFDD); 
  background: linear-gradient(to right, #FBD786, #C6FFDD); 
  border-radius: 40px 0 0 40px;
  transition: all 2s cubic-bezier(0.68, -0.35, 0.27, 1.55);
  align-items: center;
}

#cookieConsent.dismissed {
  right: -100%;
}

#cookieConsent div {
  padding: 10px 12% 10px 20px;
}

#cookieConsent img {
  height: 70px;
  width: 70px
}

#cookieConsent button {
  border: 0;
  outline: none !important;
  color: wheat;
  background: var(--main-orange);
  margin-left: 10px;
  font-weight: 700;
  border-radius: 5px;
}

/************** TOP RECOVERERS **************/

#dataSectionWrapper {
  width: 100vw;
  background: white;
  position: relative;
  z-index: 1
}

#dataSectionWrapperCurve {
  width: 100vw;
  background: #C6FFDD;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
}

.dataSectionTitle {
  color: white;
  position: absolute;
  top: 0;
  font-weight: 600;
  font-size: 40pt;
  margin: 20% 50px;
  text-transform: uppercase;
}

.dataSectionSubtitle {
  padding-left: 5px;
  font-size: 30pt;
  color: white;
  font-weight: 600;
  text-transform: lowercase;
}

#curve{
  width: 100vw;
  padding-top: 40px;
  margin-bottom: -2px;
}

#topRecovererList{
  position: absolute;
  top: 0;
  margin-top: 25%;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.topRecovererItem {
  display: flex;
  justify-content: flex-end;
  flex-direction: unset;
  font-size: 25pt;
  text-transform: uppercase;
  margin: 10px 10%;
  font-weight: 700;
}

.topRecovererItem:nth-child(1) {
  color: var(--main-pink);
}

.topRecovererItem:nth-child(2) {
  color: var(--main-orange);
}

.topRecovererItem:nth-child(3) {
  color: var(--main-green);
}

.topRecovererItem .topRecovererCount{
  font-size: 40pt;
  display: flex;
  justify-content: flex-end;
  line-height: 35px;
}

.topRecovererItem .topRecovererCountBig{
  font-size: 50pt;
}

.topRecovererName {
  text-align: right;
  opacity: 0.7
}

.topRecovererItem .percentageHighlightCountryFlagWrapper {
  text-align: right;
  margin-bottom: 20px;
  width: 350px;
  justify-content: flex-end;
  display: flex;
}

.topRecovererItem .percentageHighlightCountryFlag {
  height: 30px;
  border-radius: 50%;
  width: 30px;
  margin-left: 5px;
  display: inline-flex;
  transition: all 0.2s ease-in;
  cursor: pointer;
  background-repeat: no-repeat;
}

.topRecovererItem .percentageHighlightCountryFlagTag {
  margin-left: 5px;
  display: inline-flex;
  transition: all 0.2s ease-in;
  cursor: pointer;
  width: auto;
  height: 30px;
  border-radius: 20px;
  font-size: 16px;
  background: #ddd;
  text-align: center;
  color: var(--main-gray);
  padding: 0 10px;
  align-items: center;
}

.topRecovererItem .percentageHighlightCountryFlag:hover {
  opacity: 0.8
}

.dataSectionWrapperInner {
  background: white;
  margin-top: -5px;
  padding: 50px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dataSectionWrapperInner .tableWrapper {
  width: 100%;
  padding: 30px;
  font-size: 26px;
}

.dataTitle {
  font-size: 28pt;
  text-align: center;
  margin-bottom: 20px;
  color: var(--main-pink);
  font-weight: 700
}

/************** CARDS ****************/

.card, .card-smallest, .card-small, .card-medium, .card-large {
  height: auto;
  background: white;
  box-shadow: 3px 6px 18px rgba(0,0,0,0.25);
  position: relative;
  margin-top: 20px;
  overflow: hidden;
  text-align: center;
}


.card-smallest {
  width: 20%;
}

.card-small {
  width: 30%;
  min-height: 500px;
}

.card-medium {
  width: calc(70% - 20px);
}

.card-large {
  width: calc(80% - 20px);
}

#card-data {
  height: auto;
  max-height: 750px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.cardContentWrapperBottom {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  top:0;
}

.cardTitle {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
  margin-top: 20px;
  font-size: 20pt;
  color: var(--main-orange);
  text-transform: uppercase;
}

.cardSubtitle {
  font-style: italic;
  color: gray;
  padding: 20px;
}

/********************* COUNTRIES DATA TABLE *********/

.tableWrapper table {
  width: 100%;
  text-align: left
}

.tableWrapper td {
  padding: 10px
}

.tableWrapper thead tr th {
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  color: var(--main-gray);
  cursor: pointer;
  transition: all 0.2s ease-in;
  position: relative;
}

.tableWrapper thead tr th:hover {
  opacity: 0.7;
}

.tableWrapper thead tr th.sort {
  color: var(--main-pink);
}

.tableWrapper thead tr th:nth-child(1) {
  text-align: left
}

.tableWrapper thead tr th input#countrySearchTable {
  color: var(--main-pink);
  border: none;
  border-bottom: 2px solid gray;
  outline: none;
  width: 100%;
  max-width: 200px;
}

.tableWrapper td:not(:first-of-type) {
  text-align: center;
  font-weight: 700
}

.tableWrapper td:nth-child(1) {
  width: 50%;
  min-width: 130px
}

.tableWrapper td:nth-child(1) a {
  cursor: pointer;
}

.tableWrapper td:nth-child(1) a img {
  width: 40px;
  margin-right: 10px;
  transition: all 0.1s ease-in;
}

.tableWrapper td:nth-child(1) a:not(.selected) img {
  filter: grayscale(1);
}

.tableWrapper td:nth-child(2) {
  color: var(--main-green);
}

.tableWrapper td:nth-child(3) {
  color: gray;
}

.tableWrapper td:nth-child(4) {
  color: var(--main-green);
  width: 25%;
  text-align: left;
}

.progressBarHack {
  display: none;
}

.tableWrapper .progress {
  width: calc(100% - 100px);
  display: inline-flex;
  margin-right: 10px;
  height: 5px;
}

.tableWrapper .progress-bar {
  background-color: var(--main-green) !important
}

#loadMoreCountries {
  font-size: 16pt;
  padding-top: 10px;
  border: none;
  width: 100%;
  color: #535b62;
  text-decoration: underline;
  outline: none;
  transition: all 0.3s ease-in;
  background: white;
}

#loadMoreCountries:hover {
  color: var(--main-green);
}

.tableWrapper .progressBarHack {
  line-height: 0;
  padding: 0;
}

.tableWrapper .progressBarHack .progress {
  width: 100%;
  margin: 0;
  opacity: 0.7;
}

/*************** LINE CHART DATA *************/

.lineChartWrapper {
  padding: 30px
}

.lineChartWrapper .btn-group-toggle {
  margin-bottom: 20px;
}

.lineChartToggleWrapper {
  display: flex;
  justify-content: space-between;
}

.lineChartWrapper .btn-group-toggle .btn {
  color: var(--main-gray);
  border: 0;
  box-shadow: none;
  transition: all 0.2s ease-in;
  min-height: 38px;
}

.lineChartWrapper .btn-group-toggle .btn-primary:not(:disabled):not(.disabled):not(.active) {
  background-color: var(--light-orange);
}

.lineChartWrapper .btn-group-toggle .btn-primary:not(:disabled):not(.disabled).active {
  background-color: var(--main-orange);
}

.lineChartLegend {
  display: flex;
  justify-content: space-around;
  padding-bottom: 30px;
}

.lineChartLegendItem {
  display: flex;
  font-weight: 600;
}

.lineChartLegendMarker {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0 20px;
}

#lineChartLegendItemConfirmed {
  color: var(--main-pink);
}

#lineChartLegendItemConfirmed .lineChartLegendMarker {
  background: var(--main-pink);
}

#lineChartLegendItemRecovered {
  color: var(--main-green);
}

#lineChartLegendItemRecovered .lineChartLegendMarker {
  background: var(--main-green);
}

#lineChartLegendItemActive {
  color: var(--main-orange);
}

#lineChartLegendItemActive .lineChartLegendMarker {
  background: var(--main-orange);
}

#lineChartLegendItemPassed {
  color: var(--main-gray);
}

#lineChartLegendItemPassed .lineChartLegendMarker {
  background: var(--main-gray);
}

.dropdown-menu.show {
  height: 350px;
  overflow: scroll;
  width: 300px;
}

.dropdown-menu.show .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-menu.show input {
  width: calc(100% - 40px);
  border: none;
  border-bottom: 2px solid gray;
  margin: 10px 20px;
  outline: none;
  color: var(--main-pink);
}

.dropdown-toggle {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px !important;
  background: var(--main-orange) !important;
  border-color: var(--main-orange) !important;
  box-shadow: none !important;
}

.dropdown-toggle::after {
  position: absolute;
  right: 0;
  margin: 10px 10px 0 0;
}
/*************** TODAYS DATA *************/

#secondDataRow, #thirdDataRow {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.todayData {
  text-align: center;
}

#todayDataTitle {
  text-transform: uppercase;
  font-size: 30pt;
}

#todayDate {
  text-align: right;
  padding: 3% 3% 0 0;
  font-size: 10pt;
}

.todayDataItem {
  font-size: 18pt;
  color: var(--main-gray);
}

#todayConfirmed {
  font-size: 34pt;
  font-weight: 600;
  color: var(--main-pink);
}

#todayRecovered {
  padding-top: 15px;
  font-size: 34pt;
  font-weight: 600;
  color: var(--main-green);
}

#todayText {
  height: 100px;
  background: #C6FFDD;
  background: -webkit-linear-gradient(to right, #FBD786, #f7797d); 
  background: linear-gradient(to right, #FBD786, #f7797d);
  padding-top: 30px;
}

.borderWave {
 
}

.borderWaveFlipped {
  transform: rotate(180deg);
}

.todayData .cardSubtitle {
  padding-top: 25%
}

/*************** SHARE ******************/

.shareWrapper {
  text-align: center
}

.shareButtons {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 30px;
}

.shareButtons > button {
  padding: 15px !important;
  transition: all 0.3s ease-in;
  outline: none;
}

.shareButtons > button:hover {
  opacity: 0.7
}

.shareButtons > button:focus {
  outline: none;
  border: none;
}

/*************** NEWS ******************/

#newsWrapper {
  width: 100%;
  display: block;
  height: 800px;
}

.slickWrapper {
  outline: none;
}

.card-news {
  background: white;
  box-shadow: 3px 6px 18px rgba(0,0,0,0.25);
  position: relative;
  overflow: hidden;
  margin: 20px 10px;
  height: 500px;
  cursor: pointer;
  transition: all 0.2s ease-in
}

.card-news a:hover {
  text-decoration: none
}

.card-news:hover {
  opacity: 0.7
}

.card-news img {
  height: 50%;
  width: 100%;
  object-fit: cover;
}

.card-news-content {
  flex-direction: column;
  height: 50%;
  justify-content: space-between;
  display: flex;
}

.card-news .card-news-title {
  font-size: 16pt;
  padding: 20px 20px 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--main-gray);
}

#newsTitle {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  font-weight: 600;
  margin: 40px 0 30px 0;
  padding: 0 30px;
  font-size: 60pt;
  color: var(--main-orange)
}

/****************** TIPS **********************/

#tipsWrapper {
  justify-content: center;
  display: flex;
  position: relative;
  height: 350px;
  align-items: center;
}

#tipsTitleWrapper {
  text-align: center
}

#tipsTitle {
  font-size: 50pt;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 30px;
  color: var(--main-pink);
  padding: 0 10px;
  line-height: 42pt;
}

#tipsSubTitle {
  font-size: 24pt;
  padding-left: 55%;
  text-align: left;
  color: var(--main-pink);
  margin-bottom: 20px;
}

#tipsContentWrapper {
  padding: 0 40px;
  position: relative;
}

#tipsContentWrapper div {
  padding: 10px 0;
  font-size: 16pt;
  text-transform: lowercase;
  color: var(--main-gray);
}

#tipsWheel {
  width: 300px;
  height: 300px;
  -webkit-animation: rotation 15s infinite ease-in-out;
  animation: rotation 15s infinite ease-in-out;
}

@-webkit-keyframes rotation {
  0%, 16% { -webkit-transform: rotate(0deg); }
  16%, 33% { -webkit-transform: rotate(72deg); }
  33%, 50% { -webkit-transform: rotate(144deg); }
  50%, 66% { -webkit-transform: rotate(216deg); }
  66%, 83% { -webkit-transform: rotate(288deg); }
  83%, 100% { -webkit-transform: rotate(360deg); }
}

@keyframes rotation {
    0%, 16% { transform: rotate(0deg); }
    16%, 33% { transform: rotate(-72deg); }
    33%, 50% { transform: rotate(-144deg); }
    50%, 66% { transform: rotate(-216deg); }
    66%, 83% { transform: rotate(-288deg); }
    83%, 100% { transform: rotate(-360deg); }
}

#selectionTriangle {
  width: 0;
  height: 0;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  border-top: 170px solid #ffffffad;
  position: absolute;
  bottom: 50%;
  left: calc(50% - 80px);
}

#tipsHighlighter {
  width: 100%;
  background: #f7797d33;
  height: 40px;
  position: absolute;
  top: 7px;
  left: 20px;
  -webkit-animation: translateDown 15s infinite ease-in-out;
  animation: translateDown 15s infinite ease-in-out;
}

@-webkit-keyframes translateDown {
  0%, 16% { -webkit-transform: translateY(0px); }
  16%, 33% { -webkit-transform: translateY(50px); }
  33%, 50% { -webkit-transform: translateY(100px); }
  50%, 66% { -webkit-transform: translateY(150px); }
  66%, 83% { -webkit-transform: translateY(200px); }
  83%, 100% { -webkit-transform: translateY(0px); }
}

@keyframes translateDown {
  0%, 16% { transform: translateY(0px); }
  16%, 33% { transform: translateY(50px); }
  33%, 50% { transform: translateY(100px); }
  50%, 66% { transform: translateY(150px); }
  66%, 83% { transform: translateY(200px); }
  83%, 100% { transform: translateY(0px); }
}

@-webkit-keyframes translateDownMobile {
  0%, 16% { -webkit-transform: translateY(0px); }
  16%, 33% { -webkit-transform: translateY(30px); }
  33%, 50% { -webkit-transform: translateY(60px); }
  50%, 66% { -webkit-transform: translateY(90px); }
  66%, 83% { -webkit-transform: translateY(120px); }
  83%, 100% { -webkit-transform: translateY(0px); }
}

@keyframes translateDownMobile {
  0%, 16% { transform: translateY(0px); }
  16%, 33% { transform: translateY(34px); }
  33%, 50% { transform: translateY(68px); }
  50%, 66% { transform: translateY(102px); }
  66%, 83% { transform: translateY(136px); }
  83%, 100% { transform: translateY(0px); }
}

/*************** FOOTER *************************/

#footer {
  margin-top: 40px;
  padding: 30px;
  height: auto;
  background: #C6FFDD;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
  background: linear-gradient(to right, #f7797d, #FBD786, #C6FFDD); 
  color: var(--main-gray);
}

.footerBottomWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
}

.footerContentsWrapper {
  display: flex;
  justify-content: space-between;
}

.footerTitle {
  font-size: 14pt;
  font-weight: 600;
}

.footerText {
  padding-bottom: 10px;
}

.footerInfoWrapper, .footerSourceWrapper {
  margin: 0 30px
}

.subscribeWrapper {
  display: flex;
  text-align: center;
  justify-content: center;
}

.subscribeTitle {
  margin-bottom: 30px;
  color: var(--main-pink);
  font-weight: 700;
  font-size: 24px;
}

.mailChimpWrapper {
  background: #ffffffad;
  padding: 40px;
  border-radius: 30px 0;
  max-width: 800px;
}

.mailChimpWrapper > div > div{
  color: var(--main-gray) !important;
}

.mailChimpWrapper input {
  margin: 0 30px;
  border: 0;
  background: 0;
  border-bottom: 2px solid var(--main-pink);
  width: 60%;
  font-size: 22px;
  color: var(--main-pink);
  outline: none;
}

.mailChimpWrapper input::placeholder {
  color: var(--main-pink);
  text-transform: lowercase;
}

.mailChimpWrapper button {
  background: var(--main-pink);
  border-radius: 5px;
  padding: 7px 25px;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
  border: none;
}

.mailChimpWrapper button:hover {
  opacity: 0.7
}

.mailChimpWrapper button:focus {
  outline: 10px auto var(--main-pink);
  opacity: 1
}

.footerLinksWrapper { 
  text-align: center;
  margin-top: 30px;
}

.footerLinksWrapper button, .footerLinksWrapper a{ 
  background: none;
  border: none;
  color: var(--main-gray);
  font-size: 20px;
  transition: all 0.2s ease-in;
  padding: 10px
}

.footerLinksWrapper span { 
  padding: 0 10px;
  color: #00000040;
}

.footerLinksWrapper button:hover, .footerLinksWrapper a:hover { 
  color: var(--main-pink);
}

.footerLinksWrapper button:focus{ 
  outline: none;
}

.modal-header {
  text-transform: uppercase;
}

.footerKofiWrapper {
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: column;
  display: none
}

.kofi-title {
  margin-bottom: 10px;
  color: var(--main-gray);
  font-weight: 700;
  font-size: 18px;
}

.kofi-footer {
  height: 70px;
}

/*************** MAP *************************/
.mapCountrySelectorWrapper {
  margin-bottom: 20px;
  display: flex;
  border-radius: 15px;
  background: white;
  padding: 5px 10px;
  box-shadow: 5px 5px 10px #808080ba;
  width: 300px;
  justify-content: space-between;
  align-self: center;
  position: absolute;
  top: 0;
  left: calc(50% - 150px);
}

.mapCountrySelectorFlag {
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.mapCountrySelectorFlag:not(.selected) {
  opacity: 0.4;
  filter: saturate(2);
}

.mapCountrySelectorFlag:hover {
  opacity: 1;
  filter: saturate(1);
}


/****************************************/

#heroPlaceholder {
  height: 100vh;
}

#geochart {
  position: fixed;
  height: 100vh;
  display: flex;
  padding-top: 75px;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
}

#geoChartMap {
  opacity: 0.2;
  transition: all 1.5s ease-in;
  position: relative;
  padding-top: 35px
}

#mapTitleWrapper {
  width: 100vw;
  text-align: center
}

#mapTitle {
  font-size: 50pt;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 30px;
  color: var(--main-pink);
  padding: 0 10px;
}

#mapSubTitle {
  font-size: 20pt;
  text-align: center;
  color: var(--main-pink);
  margin-top: -20px;
  margin-bottom: 15px;
}

#geoChartMap path {
  transition: all 1s ease-in;
  stroke: none;
}

#mapError {
  padding: 50px;
  text-align: center;
  color: var(--main-gray)
}

#stayPositiveHeading {
  position: absolute;
  top: calc(100vh - 150px);
  left: 0;
}

#stayText {
  width: 100vw;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
  font-size: 24pt;
}

#stayText span {
  margin: 0 20px;
  font-weight: 500;
  line-height: 28pt;
}

#stayText span:nth-child(1) {
  color: var(--main-pink);
}

#stayText span:nth-child(2) {
  color: var(--main-orange);
}

#stayText span:nth-child(3) {
  color: var(--main-green);
}

.geochart-tooltip-recovered-title {
  color: var(--main-pink);
  font-size: 22pt;
  font-weight: 700
}

.geochart-tooltip-recovered-subtitle {
  color: var(--main-pink);
  font-size: 10pt;
  text-transform: uppercase;
}

@media (max-width: 1000px) {
  .dataSectionTitle {
    margin: 10% 50px;
    font-size: 36pt;
  }
  .dataSectionSubtitle {
    font-size: 18pt
  }
  .topRecovererItem {
    margin: 10px 40px;
    font-size: 18pt;
  }
  .topRecovererItem .topRecovererCount{
    font-size: 35pt;
  }
  .topRecovererItem .topRecovererCountBig{
    font-size: 50pt;
  }
  .topRecovererItem .percentageHighlightCountryFlagWrapper {
    width: 280px;
  }
  .topRecovererItem .percentageHighlightCountryFlag {
    height: 22px;
    width: 22px;
  }
  .topRecovererItem .percentageHighlightCountryFlagTag {
    height: 22px;
    font-size: 14px;
  }
  .card-smallest, .card-small, .card-medium, .card-large {
    width: 100%;
    margin-top: 20px;
  }
  .shareButtons {
    display: block;
    margin: 30px;
    justify-content: space-between;
  }
  .shareButtons > button {
    
  }
  .dataSectionWrapperInner{
    padding: 100px 20px 20px 20px;
  }
  #secondDataRow {
    flex-direction: column;
    justify-content: space-between;
  }
  #thirdDataRow {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .dataSectionWrapperInner .tableWrapper, .dataSectionWrapperInner .tableWrapper thead tr th  {
    font-size: 14pt;
  }
  .tableWrapper td:nth-child(1) a img {
    width: 30px
  }
  .tableWrapper td:nth-child(1) {
    width: 25%
  }
  .tableWrapper .progress {
    width: calc(100% - 70px);
  }
  #loadMoreCountries {
    font-size: 14pt;
  }
  #card-data {
    max-height: 620px
  }
  .todayData .cardSubtitle {
    padding-top: 20px
  }
}

@media (max-width: 800px) {
  #mainTitle {
    font-size: 60pt;
  }
  .progressBarHack {
    display: table-cell;
  }
  .dataSectionWrapperInner .tableWrapper {
    padding: 10px;
  }
  .tableWrapper table tr *:nth-child(4) {
    display: none
  }
  .tableWrapper table tbody td {
    padding: 15px 10px 0 10px;
  }
  .tableWrapper td:nth-child(1) {
    width: 40%
  }
  .dataSectionTitle {
    font-size: 26pt;
    margin: 7% 20px;
  }
  .dataSectionSubtitle {
    font-size: 18pt
  }
  .topRecovererItem {
    margin: 0px 20px;
    font-size: 12pt;
  }
  .topRecovererItem .percentageHighlightCountryFlagWrapper {
    width: 250px;
    margin-bottom: 25px
  }
  #tipsWrapper {
    flex-direction: column-reverse;
  }
  #mapSubTitle {
    padding-top: 5px;
  }
  #tipsWrapper {
    justify-content: flex-end;
    height: 600px;
  }
  #tipsHighlighter {
    height: 30px;
    top: 22px;
    left: 0;
    -webkit-animation: translateDownMobile 15s infinite ease-in-out;
    animation: translateDownMobile 15s infinite ease-in-out;
  }
  #tipsSubTitle {
    padding-left: 0;
    text-align: center;
    margin: 0;
  }
  #tipsContentWrapper {
    padding: 20px;
    text-align: center
  }
  #tipsContentWrapper div {
    padding: 5px 0;
    font-size: 12pt;
  }
  .footerContentsWrapper {
    flex-direction: column;
  }
  .footerInfoWrapper, .footerSourceWrapper {
    margin: 0
  }
  .mailChimpWrapper > * > *{
    margin: 20px 0;
  }
  #cookieConsent {
    font-size: 12px;
    bottom: -40px;
  }
}

@media (max-width: 600px) {
  #rainbowGraphic {
    justify-content: none;
  }
  #stayText {
    font-size: 18pt;
  }
  #card-data {
    max-height: 580px;
  }
  .cardTitle {
    font-size: 16pt;
  }
  .dataSectionWrapperInner {
    padding: 100px 0 0 0;
  }
  .lineChartWrapper {
    padding: 10px;
  }
  .lineChartLegend {
    flex-direction: column;
  }
  #mapTitle, #tipsTitle {
    font-size: 30pt;
  }
  #mapSubTitle, #tipsSubTitle {
    font-size: 14pt;
  }
  .menu-subtitle {
    display: none;
  }
  .menu-subtitle-mobile {
    display: flex;
  }
  .dataSectionWrapperInner .tableWrapper, .dataSectionWrapperInner .tableWrapper thead tr th  {
    font-size: 10pt;
  }
  .tableWrapper td:nth-child(1) a img {
    width: 30px
  }
  .tableWrapper td:nth-child(1) {
    width: auto
  }
  #loadMoreCountries {
    font-size: 12pt;
  }
  #newsTitle {
    line-height: 70px;
    padding: 0 50%;
  }
  .topRecovererItem .topRecovererCount{
    font-size: 20pt;
  }
  .topRecovererItem .topRecovererCountBig{
    font-size: 26pt;
  }
  .topRecovererItem .percentageHighlightCountryFlagWrapper {
    width: 200px;
    margin-bottom: 10px
  }
  .topRecovererItem .percentageHighlightCountryFlag {
    height: 18px;
    width: 18px;
  }
  .topRecovererItem .percentageHighlightCountryFlagTag {
    height: 18px;
    font-size: 11px;
    padding: 0 7px;
  }
  #cookieConsent.dismissed {
    right: -150%;
  }
  #cookieConsent {
    font-size: 11px;
    bottom: -45px;
  }
  .footerKofiWrapper {
    display: flex
  }
}

@media (max-width: 400px) {
  #mainTitle {
    font-size: 50pt;
  }
  .dataSectionTitle {
    font-size: 20pt;
    margin: 7% 20px;
  }
  #topRecovererList {
    margin-top: 30%
  }
  #cookieConsent {
    font-size: 10px;
    bottom: -20px;
  }
  .topRecovererItem .percentageHighlightCountryFlagWrapper {
    width: 160px;
    margin-bottom: 20px
  }
  .topRecovererItem .percentageHighlightCountryFlagTag {
    font-size: 10px;
  }
}